<template>
  <div
    :id="`inner-${msgId}`"
    class="message-item"
  >
    <MessageHead
      class="message-item-head"
      :username="username"
      :source="source"
      :order-no="orderNo"
      :profile-image="profileImage"
      :fallback-image="fallbackImage"
      :date="dateStr"
      :user-link="userLink"
    >
      <template #right>
        <div class="right-block">
          <SentimentCircleIcon
            :data="item"
            :small="true"
          />
        </div>
      </template>
    </MessageHead>

    <div class="content-wrapper">
      <div
        v-if="raw && raw.title"
        class="message-title"
      >
        {{ raw.title }}
      </div>
      <span class="message-content">
        {{ textContent }}
      </span>
      <!-- <a v-if="isLongText" class="seemore" @click="toggleFullText">
        <span v-if="showFullText">See Less</span>
        <span v-else>See More</span>
      </a> -->
    </div>
    <EngagementBarKeyHighlight
      v-if="!isRetweet"
      class="engage-bar"
      :class="{ 'no-category': !showCategory }"
      :data="item"
      :isMobile="isMobileScreen"
    />
  </div>
</template>

<script>
import { toRefs, computed, ref, onMounted, watch } from 'vue';
import imagesLoaded from 'imagesloaded';
import MessageHead from './MessageHead.vue';
import EngagementBarKeyHighlight from './EngagementBarKeyHighlight.vue';
import SentimentCircleIcon from './SentimentCircleIcon.vue';

import helper from '@/services/helper';

export default {
  components: {
    MessageHead,
    EngagementBarKeyHighlight,
    SentimentCircleIcon,
  },
  // inject: ['subCategoryName'],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isCount: Boolean,
    orderNo: {
      type: Number,
      default: 1,
    },
    showCategory: Boolean,
    editable: Boolean,
    hasMedia: Boolean,
    hasThumb: Boolean,
    gridItem: Boolean,
    windowWidth: {
      type: Number,
      default: 600,
    },
    updateVar: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { item, isCount, gridItem, hasThumb } = toRefs(props);
    const showFullText = ref(false);
    const toggleFullText = () => {
      showFullText.value = !showFullText.value;
    };
    const msgId = computed(() => {
      if (item.value && item.value.info) {
        const { id } = item.value.info;
        return id;
      }
      return '';
    });
    const username = computed(() => {
      if (item.value && item.value.user) {
        const { username } = item.value.user;
        return username;
      }
      return 'User';
    });
    const source = computed(() => {
      if (item.value && item.value.info) {
        const { source } = item.value.info;
        return source;
      }
      return 'others';
    });
    const profileImage = computed(() => {
      if (item.value && item.value.user) {
        const { photo } = item.value.user;
        return photo;
      }
      return 'User';
    });
    const fallbackImage = computed(() => {
      if (item.value && item.value.user) {
        const { fallback_photo } = item.value.user;
        return fallback_photo;
      }
      return 'User';
    });
    const userLink = computed(() => {
      if (item.value && item.value.user) {
        const { link } = item.value.user;
        return link;
      }
      return '';
    });
    const unitText = computed(() => {
      if (isCount.value) {
        return 'Mentions';
      }
      return 'Engagements';
    });
    const dateStr = computed(() => {
      if (item.value && item.value.info) {
        const { created_at } = item.value.info;
        return helper.formatDate(created_at, 'D MMM YYYY, H:mm A');
      }
      return '...';
    });
    const isLongText = computed(() => {
      if (item.value && item.value.raw_data) {
        const { text } = item.value.raw_data;
        if (text && text.length > 30) return true;
      }
      return false;
    });
    const textContent = computed(() => {
      if (item.value && item.value.raw_data) {
        const { text } = item.value.raw_data;
        return text;
        // if (text && text.length > 30) {
        //   if (showFullText.value) {
        //     return text;
        //   }
        //   return helper.truncate(text) + '...';
        // } else {
        //   return text;
        // }
      }
      return '';
    });
    const imageUrl = computed(() => {
      if (item.value && item.value.raw_data) {
        const { image } = item.value.raw_data;
        return image;
      }
      return '';
    });
    const currentSourceColor = computed(() => {
      let color = '#2C99FF';
      if (item.value && item.value.info) {
        const { source } = item.value.info;
        if (source === 'facebook') color = '#1877F2';
        if (source === 'twitter') color = '#1DA1F2';
        if (source === 'pantip') color = '#7674BC';
        if (source === 'youtube') color = '#FF0000';
        if (source === 'instagram') color = '#E4405F';
        if (source === 'website') color = '#2C99FF';
      }
      return color;
    });

    const resizeItem = (msgId) => {
      if (gridItem.value) {
        const inner = document.getElementById(`inner-${msgId}`);
        const msg = document.getElementById(`msg-${msgId}`);
        const grid = document.getElementsByClassName('masonry')[0];
        if (grid) {
          const rowGap = parseInt(
              window.getComputedStyle(grid).getPropertyValue('grid-row-gap')
            ),
            rowHeight = parseInt(
              window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')
            );
          const rowSpan = Math.ceil(
            (inner.getBoundingClientRect().height + rowGap) /
              (rowHeight + rowGap)
          );

          msg.style.gridRowEnd = 'span ' + rowSpan;
        }
      }
    };

    watch(
      () => props.windowWidth,
      () => {
        if (msgId.value) {
          resizeItem(msgId.value);
        }
      }
    );
    watch(
      () => props.updateVar,
      () => {
        // console.log('OHH its time', msgId.value);
        setTimeout(() => {
          if (msgId.value) {
            resizeItem(msgId.value);
          }
        });
      }
    );

    onMounted(() => {
      if (msgId.value) {
        const elem = document.getElementById(`img-${msgId.value}`);
        if (elem) {
          imagesLoaded(elem, () => {
            // console.log('OH YEAH', elem);
            resizeItem(msgId.value);
          }).on('fail', () => {
            // console.log('FAILed');
            elem.remove();
            resizeItem(msgId.value);
          });
        } else {
          // calculate height without image
          resizeItem(msgId.value);
        }
      } else {
        // console.log('Naaaa');
      }
      if (hasThumb.value) {
        const elem = document.getElementById(`thumb-${msgId.value}`);
        if (elem) {
          imagesLoaded(elem, () => {}).on('fail', () => {
            elem.remove();
          });
        }
      }
    });

    return {
      username,
      userLink,
      source,
      profileImage,
      unitText,
      dateStr,
      textContent,
      isLongText,
      showFullText,
      toggleFullText,
      raw: item.raw_data,
      imageUrl,
      msgId,
      currentSourceColor,
      fallbackImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-item {
  // height: 200px;
  // min-height: 200px;
  // min-width: 350px;
  // width: 350px;
  display: flex;
  flex-direction: column;

  .right-block {
    display: flex;
    align-items: center;

    .more-menu {
      margin-left: 18px;
      color: #adb4d2;
    }
  }
  a .profile-img {
    width: 30px !important;
    height: 30px !important;
    border-radius: 30px;
  }

  .message-head {
    align-items: start;
  }
  .message-item-head {
    min-height: 60px;
    max-height: 60px;
    // padding: 20px 20px 0px;
    // padding: 0 20px;
  }
  .message-title {
    // font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #272b41;
  }

  .message-content {
    min-height: 50px;
    max-height: 50px;
    font-size: 12px;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .content-wrapper {
    // padding: 0 20px 14px 20px;
    font-size: 15px;
    line-height: 24px;
    margin: 16px 0 0 0;
    // margin: 16px 0;
    color: #5a5f7d;
    border-bottom: 1px solid #f1f2f6;
    .hashtag-wrapper {
      margin-bottom: 18px;
    }
    .hashtag {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
  .engage-bar {
    // padding: 0 20px;
    margin-top: 8px;
    // margin-bottom: 14px;
    &.no-category {
      margin-bottom: 36px;
    }
  }
  .seemore {
    margin-left: 3px;
    color: #333;
    font-weight: 500;
  }
  .media-wrapper {
    border-top: 1px solid #f1f2f6;
    margin-top: 15px;
    .media-img {
      width: 100%;
      height: auto;
    }
  }
  .thumbnail-wrapper {
    margin-top: 16px;
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    .thumb-img {
      width: 80px;
      height: 80px;
      background-color: #adb4d2;
    }
  }
}
</style>
