<template>
  <a-card
    class="trending-comparison-wrapper"
    :widget="true"
    :title="'Trending'"
  >
    <template
      v-if="!isMobileScreen"
      #extra
    >
      <a-button
        :type="chartType === 'line' && !noData ? 'primary' : 'ghost'"
        class="circular"
        :class="{ 'button-primary-light': chartType === 'line' && !noData }"
        style="width: 42px"
        :disabled="noData"
        @click="chartType = 'line'"
      >
        <FeatherIcon
          type="trending-up"
          size="18"
        />
      </a-button>
    </template>
    <template
      v-else
      #extra
    >
      <a-dropdown
        placement="bottomRight"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <FeatherIcon
          class="more-menu"
          type="more-horizontal"
          size="16"
        />
        <template #overlay>
          <a-menu id="mode-menu-mobile">
            <a-menu-item-group title="Graph:">
              <a-menu-item style="background: #fff">
                <a-button
                  :type="chartType === 'line' && !noData ? 'primary' : 'ghost'"
                  class="circular"
                  :class="{
                    'button-primary-light': chartType === 'line' && !noData,
                  }"
                  style="width: 42px"
                  :disabled="noData"
                  @click="chartType = 'line'"
                >
                  <FeatherIcon
                    type="trending-up"
                    size="18"
                  />
                </a-button>
                <a-button
                  :type="
                    chartType === 'column' && !noData ? 'primary' : 'ghost'
                  "
                  class="circular"
                  :class="{
                    'button-primary-light': chartType === 'column' && !noData,
                  }"
                  style="width: 42px"
                  :disabled="noData"
                  @click="chartType = 'column'"
                >
                  <FeatherIcon
                    type="bar-chart"
                    size="18"
                  /> </a-button
              ></a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
      </a-dropdown>
      <!-- <a-button
        :type="chartType === 'line' ? 'primary' : 'ghost'"
        class="circular"
        :class="{ 'button-primary-light': chartType === 'line' }"
        style="width: 42px"
        @click="chartType = 'line'"
      >
        <FeatherIcon class="more-menu" type="more-horizontal" size="24" />
      </a-button> -->
    </template>
    <div
      v-if="loading"
      class="loading-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>

    <TrendingChart
      v-else-if="!loading && !noData"
      class="chart"
      :chart-type="chartType"
      :series="selectedSerie"
    ></TrendingChart>

    <div
      v-if="!loading && noData"
      class="no-data-wrapper"
    >
      <NoResult></NoResult>
    </div>
  </a-card>
</template>

<script>
import { toRefs, ref, reactive, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import api from '@/services/api';
import helper from '@/services/helper';
import chartHelper from '@/services/chartHelper';
import TrendingChart from '@/components/Chart/TrendingChart';
import NoResult from '@/components/Error/NoResult.vue';

export default {
  components: {
    TrendingChart,
    NoResult,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filterResult: Object,
  },
  setup(props) {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const categoryNameMap = computed(
      () => store.getters['config/defaultCategoryVisibleMap']
    );
    const chartType = ref('line');
    const seriesData = reactive({
      engagement: [],
      mentioned: [],
      view: [],
    });
    const selectedSerie = ref([]);
    let loading = ref(false);
    const noData = ref(false);

    const { mode, filterResult } = toRefs(props);

    const currentGraphCriteria = ref({});
    const drilldownFn = (pointOption, payload = { title: 'data' }) => {
      const { graphTime } = currentGraphCriteria.value;
      const { x } = pointOption;
      const { time, title } = helper.getTimeOpt(x, graphTime);

      const { filterData, level } = filterResult.value;
      const newFilter = JSON.parse(JSON.stringify(filterData));

      let f = {
        payload: {
          title: `${payload.title} ${title}`,
        },
        criteria: {
          // ...filterResult.value.filterData,
          ...newFilter,
          time,
          // ...payload.criteria,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };

      if (level > 0) {
        const category = payload.criteria.subCategory; // selected
        for (let i in f.criteria['subCategory']) {
          if (f.criteria['subCategory'][i].level == level) {
            f.criteria['subCategory'][i] = category[0];
          }
        }
      } else {
        const category = payload.criteria.category; // selected
        f.criteria['category'] = category;
      }
      store.dispatch('message/showMessageModal', f);
    };

    const init = async () => {
      loading.value = true;
      if (filterResult.value && filterResult.value.filterData) {
        const { filterData, categoryList, level } = filterResult.value;
        if (filterResult.value.valid) {
          const { graphCriteria, granularity } = chartHelper.modifyGranularity(
            {},
            filterData
          );
          currentGraphCriteria.value = graphCriteria;
          const filterCriteria = {
            ...filterData,
            ...graphCriteria,
          };

          const result = await api
            .getCategoryHistoryGraph(filterCriteria, { level })
            .catch(() => {
              console.error('History api call error', result);
            });
          if (
            result.message &&
            result.message.graphData &&
            Object.keys(result.message.graphData).length !== 0
          ) {
            // console.log(filterData, categoryList, level);
            let c = categoryList;
            if (c.length === 0) {
              c = categoryNameMap.value[level];
            }
            // console.log('DEFF', c);

            const graphResult = helper.prepareCategoryChartECV(
              result.message.graphData,
              new Set(categoryNameMap.value[level]),
              drilldownFn,
              level,
              c,
              granularity
            );

            // console.log(graphResult);

            Object.assign(seriesData, graphResult);
            selectedSerie.value = seriesData[mode.value].sort((a, b) => {
              if (a.name === 'Others') {
                return 1;
              } else if (b.name === 'Others') {
                return -1;
              } else {
                return 0;
              }
            });
            // check no data
            checkSelectedSerieData();
          } else {
            noData.value = true;
          }
        } else {
          // TODO add no data;
          let c = categoryList;
          if (c.length === 0) {
            c = categoryNameMap.value[level];
          }
          let arr = [];
          for (let i in c) {
            const category = c[i];
            // const color = helper.getColor(category, i);
            const color = helper.getColorByCategory(level, category, i);
            arr.push({
              name: i,
              color: color,
              data: [[0, 0]],
            });
          }
          Object.assign(seriesData, {
            engagement: arr,
            mentioned: arr,
            view: arr,
          });
          selectedSerie.value = seriesData[mode.value];
        }
      }

      loading.value = false;
    };

    const checkSelectedSerieData = () => {
      if (selectedSerie.value && selectedSerie.value.length > 0) {
        noData.value = false;
      } else {
        noData.value = true;
      }
    };

    // watch(filterResult.value.filterData, () => {
    //   init();
    // });

    watch(filterResult.value, () => {
      init();
    });

    watch(mode, () => {
      // if has data just change active mode
      if (seriesData[mode.value]) {
        selectedSerie.value = [...seriesData[mode.value]];
      } else {
        init();
      }
    });

    // const selectedSerie = computed(() => {
    //   let result = [];
    //   if (mode.value && seriesData[mode.value]) {
    //     console.log(mode.value, seriesData[mode.value]);
    //     result = seriesData[mode.value];
    //   }
    //   return result;
    // });

    onMounted(() => {
      init();
    });

    return {
      chartType,
      selectedSerie,
      loading,
      noData,
      isMobileScreen,
    };
  },
};
</script>

<style lang="scss">
@import '../../config/theme/colors.json';
.trending-comparison-wrapper {
  .mode-dropdown-mobile {
    width: 104px;
    height: 100px;
  }
  .button-primary-light {
    /* TODO Refactor to outline btn */
    background: #2c99ff15;
    border-width: 0px;
    color: $primary-color;
    &:focus {
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;
    }
  }

  .loading-wrapper,
  .no-data-wrapper {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
  }

  .ant-dropdown {
    .ant-dropdown-menu {
      min-width: 104px !important;
      height: 100px;
    }
  }

  .more-menu {
    color: #9299b8;
  }

  .mode-mobile {
    width: 104px;
    height: 100px;
    box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.2);
    border-radius: 6px;
  }
}
</style>
