<template>
  <a-card
    class="engage-mention-comparison no-body-padding"
    title="Platforms Mention & Engagement"
  >
    <div
      v-if="loading"
      class="loading-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>

    <div
      v-show="!loading && hasData"
      class="chart-wrapper"
    >
      <v-chart
        ref="emChart"
        class="chart"
        :option="chartOptions"
        :autoresize="true"
        @click="onChartClick"
      />
    </div>

    <NoResult
      v-if="!loading && !hasData"
      class="no-data-wrapper"
    ></NoResult>
  </a-card>
</template>

<script>
import { toRefs, watch, ref, reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

import VChart from 'vue-echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { use } from 'echarts/core';
import { ScatterChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import NoResult from '@/components/Error/NoResult.vue';

use([
  CanvasRenderer,
  ScatterChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

import api from '@/services/api';
import helper from '@/services/helper';

export default {
  components: {
    VChart,
    NoResult,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filterResult: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const hasData = computed(() => {
      return seriesData.value.length > 0;
    });
    const emChart = ref(null);
    let loading = ref(false);

    const store = useStore();
    const categoryNameMap = computed(
      () => store.getters['config/defaultCategoryVisibleMap']
    );
    const isMobileScreen = computed(() => store.state.isMobileScreen);

    const { filterResult } = toRefs(props);
    // const store = useStore();

    const chartOptions = reactive({
      // TODO log or value mode
      yAxis: { type: 'log', name: 'Engagement' },
      xAxis: { type: 'log', name: 'Mentions' },
      series: [],
      color: [],
      grid: {
        left: 80,
        right: 80,
      },
      tooltip: {
        formatter: (params) => {
          // console.log(params); // color
          const { data, seriesName, color } = params;
          const mentions = helper.numeral(data[0]);
          const engagements = helper.numeral(data[1]);
          return `
          <span style="color: ${color}">\u25CF</span>
            ${seriesName} <br /> Mentions: ${mentions} <br /> Engagement: ${engagements}
          `;
        },
      },
    });

    let seriesData = ref([]);
    let colorList = [];
    const init = async (isSetOption) => {
      loading.value = true;
      if (filterResult.value && filterResult.value.filterData) {
        const { filterData, categoryList, level } = filterResult.value;
        let c = categoryList;
        if (c.length === 0) {
          c = categoryNameMap.value[level];
        }
        if (filterResult.value.valid) {
          const result = await api
            .getCategoryHistoryGraph(filterData, { level })
            .catch(() => {
              console.error('History api call error', result);
            });
          if (
            result.message &&
            result.message.graphData &&
            Object.keys(result.message.graphData).length !== 0
          ) {
            // Prepare eChart scatter
            seriesData.value = [];
            colorList = [];
            if (c) {
              for (let categoryKey of c) {
                const categoryObj = result.message.graphData[categoryKey];
                // Todo check visible?
                // if (categoryName === 'others') {
                //   continue;
                // }
                // Calculate total engage as y, count as x
                let totalEngage = 0;
                let totalCount = 0;
                if (categoryObj) {
                  for (let i of categoryObj.data) {
                    totalCount += i.y.count;
                    totalEngage += i.y.engagement;
                  }

                  seriesData.value.push({
                    type: 'scatter',
                    name: categoryKey,
                    data: [[totalCount || 1, totalEngage || 1]],
                    symbolSize: 20,
                    label: {
                      show: true,
                      formatter: '{a}',
                      position: 'right',
                      distance: 5,
                    },
                  });
                  colorList.push(
                    helper.getColorByCategory(
                      level,
                      categoryKey,
                      seriesData.value.length - 1
                    )
                  );
                  // colorList.push(helper.getColor(categoryKey, seriesData.length - 1));
                }
              }
            }
            chartOptions.series = seriesData.value;
            chartOptions.color = colorList;
            if (isSetOption) {
              loading.value = false;
              emChart.value.setOption(chartOptions, true);
            }
          }
        }
      }
      loading.value = false;
    };

    const onChartClick = (e) => {
      const { filterData, level } = filterResult.value;
      const newFilter = JSON.parse(JSON.stringify(filterData));
      const category = [e.seriesName]; // selected
      const filter = {
        payload: {
          title: `Data of "${e.seriesName}" category`,
        },
        criteria: {
          category: newFilter.category,
          subCategory: newFilter.subCategory,
          time: newFilter.time,
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      if (level > 0) {
        for (let i in filter.criteria['subCategory']) {
          if (filter.criteria['subCategory'][i].level == level) {
            filter.criteria['subCategory'][i].category = category;
          }
        }
      } else {
        filter.criteria['category'] = category;
      }
      store.dispatch('message/showMessageModal', filter);
    };

    watch(filterResult.value, () => {
      init(true);
    });

    onMounted(() => {
      setTimeout(() => {
        init();
      }, 400);
    });

    return {
      hasData,
      emChart,
      loading,
      chartOptions,
      onChartClick,
      isMobileScreen,
    };
  },
};
</script>

<style lang="scss">
.engage-mention-comparison {
  .ant-card-head {
    min-height: 67px;
  }
  .no-data-wrapper {
    min-height: 400px;
  }
  .chart-wrapper {
    width: 100%;
    min-height: 400px;
    .chart {
      height: 400px;
    }
  }

  .loading-wrapper {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px;
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }
}
</style>
